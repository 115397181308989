import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0];

export const dictionary = {
		"/[lang]": [~2],
		"/[lang]/action": [~3],
		"/[lang]/booking/form": [~4],
		"/[lang]/booking/payment": [~5],
		"/[lang]/complains": [6],
		"/[lang]/confirm-email": [~7],
		"/[lang]/contact": [8],
		"/[lang]/find-my-driver": [~9],
		"/[lang]/general-info": [10],
		"/[lang]/how-to-book": [11],
		"/[lang]/landing/weekend": [12],
		"/[lang]/login": [13],
		"/[lang]/lost-and-founds": [~14],
		"/[lang]/register": [~15],
		"/[lang]/request-estimate": [~16],
		"/[lang]/reset-password": [17],
		"/[lang]/services": [18],
		"/[lang]/success": [19],
		"/[lang]/user/[slug]": [~20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';